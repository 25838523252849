<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-email</v-icon>
          <h1 class="darkGrey--text">Auto-Notifications</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Users
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create', blank)"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Group
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col class="d-flex flex-row">
        <v-checkbox
          color="secondary"
          label="Show Deactivated Auto-Notifications"
          v-model="deactivatedGroups"
          hide-details
          class="custom-checkbox-small"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          @change="getDataFromApi"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="groups"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :loading="loading"
            :search="search"
          >
            <template v-slot:item.name="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.group_name }}
                </span>
                <span class="text-caption">
                  {{ item.description }}
                </span>
              </span>
            </template>
            <template v-slot:item.status="{ item }">
              <span class="text-caption">
                {{ item.status }}
              </span>
            </template>
            <template v-slot:item.linkedboards="{ item }">
              <v-tooltip top v-if="item.linkedboards>0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.linkedboards }}
                  </v-chip>
                </template>
                <span class="custom-tooltip-list">
                  <v-row>
                    <v-col>
                      <span class="custom-tooltip-title">
                        BOARDS
                      </span>
                      <ul v-bind:class="{ 'custom-list-big': item.linkedboards>16 }">
                        <li v-for="board in boardsSample" :key="board">
                          {{ board }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-center">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'AutoNotificationMembers' })"
                    >
                      <v-icon size="20">
                        mdi-account-multiple
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Manage Members
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <auto-notification-create-update v-model="modals.createUpdate" :modeType="modeType" :itemContent="this.itemContent" @reload="getDataFromApi"></auto-notification-create-update>
    <auto-notification-members v-model="modals.members"></auto-notification-members>
    <auto-notification-activate v-model="modals.activate" :itemContent="this.itemContent" @reload="getDataFromApi"></auto-notification-activate>
    <auto-notification-deactivate v-model="modals.deactivate" :itemContent="this.itemContent" @reload="getDataFromApi"></auto-notification-deactivate>
    <auto-notification-delete v-model="modals.delete" :itemContent="this.itemContent" @reload="getDataFromApi"></auto-notification-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import AutoNotificationCreateUpdate from '@/components/AutoNotifications/AutoNotificationCreateUpdate'
import AutoNotificationMembers from '@/components/AutoNotifications/AutoNotificationMembers'
import AutoNotificationActivate from '@/components/AutoNotifications/AutoNotificationActivate'
import AutoNotificationDeactivate from '@/components/AutoNotifications/AutoNotificationDeactivate'
import AutoNotificationDelete from '@/components/AutoNotifications/AutoNotificationDelete'
import NotificationGroup from '@/models/admin/NotificationGroup'

export default Vue.extend({
  name: 'AutoNotifications',
  components: {
    Portal,
    AutoNotificationCreateUpdate,
    AutoNotificationMembers,
    AutoNotificationActivate,
    AutoNotificationDeactivate,
    AutoNotificationDelete
  },
  data: () => ({
    blank: {},
    modeType: '',
    options: {},
    search: '',
    deactivatedGroups: false,
    groups: [],
    totalGroups: 0,
    itemContent: {},
    modals: { createUpdate: false, delete: false, activate: false, deactivate: false, members: false },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Auto-Notifications',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-close-circle-outline', text: 'Deactivate', type: 'important', actions: 'deactivate' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Group Name', value: 'name' },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    loading: false
  }),
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.getDataFromApi()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  mounted () {
    this.options = {
      page: 1,
      itemsPerPage: 10
    }
    this.getDataFromApi()
  },
  computed: {
    notificationGroupStatus () {
      return (this.deactivatedGroups) ? '' : 'active'
    }
  },
  watch: {
    deactivatedGroups () {
      this.getDataFromApi()
    }
  },
  methods: {
    openModal (item, optional, itemContent) {
      if (optional) this.modeType = optional
      if (itemContent) this.itemContent = itemContent
      this.modals[item] = true
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []

      if (item.status === 'active') {
        itemsToRemove.push('activate')
      }

      if (item.status === 'inactive') {
        itemsToRemove.push('deactivate')
      }

      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    },
    getDataFromApi () {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      NotificationGroup
        .page(page)
        .where('group_name', this.search)
        .where('description', this.search)
        .where('status', this.notificationGroupStatus)
        .where('client_id', this.$store.state.client.activeClient.clientID)
        .orderBy(orderBy)
        .get()
        .then(response => {
          this.groups = response.data
          this.totalGroups = response.total
          this.loading = false
        })
    }
  }
})
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-breadcrumbs',{staticClass:"pa-0 mb-8",attrs:{"items":_vm.breadcrumbsItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"secondary","size":"36"}},[_vm._v("mdi-email")]),_c('h1',{staticClass:"darkGrey--text"},[_vm._v("Auto-Notifications")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"darkGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" Explanation for Users ")])])],1),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","height":"40","color":"orange lighten-2"},on:{"click":function($event){return _vm.openModal('createUpdate', 'create', _vm.blank)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-plus")]),_vm._v(" Add Group ")],1)],1)],1),_c('v-row',{staticClass:"my-6"},[_c('v-col',[_c('v-divider',{staticClass:"custom-divider"})],1)],1),_c('v-row',{staticClass:"d-flex mb-6"},[_c('v-col',{staticClass:"d-flex flex-row"},[_c('v-checkbox',{staticClass:"custom-checkbox-small",attrs:{"color":"secondary","label":"Show Deactivated Auto-Notifications","hide-details":""},model:{value:(_vm.deactivatedGroups),callback:function ($$v) {_vm.deactivatedGroups=$$v},expression:"deactivatedGroups"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"change":_vm.getDataFromApi},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.groups,"footer-props":{
            'items-per-page-options': [10, 20, 30]
          },"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"py-6 d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.group_name)+" ")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.description)+" ")])])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.linkedboards",fn:function(ref){
          var item = ref.item;
return [(item.linkedboards>0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.linkedboards)+" ")])]}}],null,true)},[_c('span',{staticClass:"custom-tooltip-list"},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"custom-tooltip-title"},[_vm._v(" BOARDS ")]),_c('ul',{class:{ 'custom-list-big': item.linkedboards>16 }},_vm._l((_vm.boardsSample),function(board){return _c('li',{key:board},[_vm._v(" "+_vm._s(board)+" ")])}),0)])],1)],1)]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"darkGrey","size":"18"},on:{"click":function($event){return _vm.$router.push({ name: 'AutoNotificationMembers' })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-account-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Manage Members ")])]),_c('v-menu',{attrs:{"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item-group',_vm._l((_vm.itemsMenuFiltered(item)),function(itemMenu,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.openModal(itemMenu.actions, itemMenu.optional, item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":itemMenu.type? itemMenu.type:'darkGrey'}},[_vm._v(" "+_vm._s(itemMenu.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:itemMenu.type? itemMenu.type+'--text':'darkGrey--text'},[_vm._v(" "+_vm._s(itemMenu.text)+" ")])],1)],1)}),1)],1)],1)],1)]}}])})],1)],1)],1),_c('auto-notification-create-update',{attrs:{"modeType":_vm.modeType,"itemContent":this.itemContent},on:{"reload":_vm.getDataFromApi},model:{value:(_vm.modals.createUpdate),callback:function ($$v) {_vm.$set(_vm.modals, "createUpdate", $$v)},expression:"modals.createUpdate"}}),_c('auto-notification-members',{model:{value:(_vm.modals.members),callback:function ($$v) {_vm.$set(_vm.modals, "members", $$v)},expression:"modals.members"}}),_c('auto-notification-activate',{attrs:{"itemContent":this.itemContent},on:{"reload":_vm.getDataFromApi},model:{value:(_vm.modals.activate),callback:function ($$v) {_vm.$set(_vm.modals, "activate", $$v)},expression:"modals.activate"}}),_c('auto-notification-deactivate',{attrs:{"itemContent":this.itemContent},on:{"reload":_vm.getDataFromApi},model:{value:(_vm.modals.deactivate),callback:function ($$v) {_vm.$set(_vm.modals, "deactivate", $$v)},expression:"modals.deactivate"}}),_c('auto-notification-delete',{attrs:{"itemContent":this.itemContent},on:{"reload":_vm.getDataFromApi},model:{value:(_vm.modals.delete),callback:function ($$v) {_vm.$set(_vm.modals, "delete", $$v)},expression:"modals.delete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }